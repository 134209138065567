import React from "react";
import style from "./rev.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const Rev = ({ data }) => {
  const swiperOptions = {
    loop: true,
    centeredSlides: true,
    spaceBetween: 0,
    navigation: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    pagination: {
      clickable: true,
    },
    breakpoints: {
      500: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1,
      },
      900: {
        slidesPerView: 1,
      },
      1024: {
        slidesPerView: 1,
      },
    },
  };
  return (
    <div id="about">
      <div className={style.mainContainer}>
        <div className={`my-5 ${style.mainDiv} position-relative `}>
          <div className={`mt-5 pt-5`}>
            <p className={` text-center pt-5    mb-1 ${style.intro}`}>
              promotion
            </p>
            <p className={`m-0 p-0 text-center mb-1 vol ${style.title}`}>
              See What Our Clients Say About Us
            </p>
          </div>
          <div className="row justify-content-center">
            <Swiper
              className="col-12 col-md-7 mt-3 pt-1 d-flex justify-content-center align-items-center"
              modules={[Autoplay, Navigation, Pagination]}
              {...swiperOptions}
            >
              {data.map((item, index) => (
                <SwiperSlide
                  className="d-flex flex-column gap-3 align-items-center justify-content-center"
                  key={index}
                >
                  <img
                    loading="lazy"
                    alt="avatar"
                    className={style.mainImg}
                    src={item.img}
                  />
                  <p className={`m-0 p-0 ${style.desc}`}>{item.desc}</p>
                  <p className={`m-0 p-0 mb-5 ${style.name}`}>{item.name}</p>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rev;
