import React from "react";
import style from "./romantic.module.css";
import romantic1 from "../../../assets/romantic.png";
const Romantic = () => {
  return (
    <div className="container  my-5 pt-5">
      <div className="row mt-5 align-items-center justify-content*center">
        <div className="col-12 col-md-6 m-0 p-0 mb-3 mb-md-0">
          <img
            loading="lazy"
            alt="romantic/img"
            src={romantic1}
            className={style.romantic}
          />
        </div>
        <div className="col-11 col-md-6   mb-3 mb-md-0 ">
          <p className={`m-0 p-0 ${style.intro}`}>Honeymoon Specials</p>
          <p className={`m-0 p-0 vol mb-2 ${style.title}`}>
            Our Romantic Tropical Destinations
          </p>
          <p className={`m-0 p-0 ${style.desc}`}>
            Et labore harum non nobis ipsum eum molestias mollitia et corporis
            praesentium a laudantium internos. Non quis eius quo eligendi
            corrupti et fugiat nulla qui soluta recusandae in maxime quasi aut
            ducimus illum aut optio quibusdam!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Romantic;
/**
 *  
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="480"
            height="713"
            viewBox="0 0 480 713"
            fill="none"
            className={style.svg}
          >
            <path
              d="M2 712.484V239.738C2 108.183 108.646 1.53711 240.201 1.53711V1.53711C371.756 1.53711 478.402 108.183 478.402 239.738V712.484"
              stroke="url(#paint0_linear_1_315)"
              stroke-width="2.08948"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1_315"
                x1="240.201"
                y1="1.53711"
                x2="240.201"
                y2="712.484"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#A7327A" />
                <stop offset="1" stop-color="#3B90FB" />
              </linearGradient>
            </defs>
          </svg>
 */
/**
 *    <div className="col-12 col-md-6 m-0 p-0 mb-3 mb-md-0 position-relative ">
          <img
            loading="lazy"
            alt="romantic-main"
            src={mainImg}
            className={style.mainImg}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="480"
            height="713"
            viewBox="0 0 480 713"
            fill="none"
            className={style.svg}
          >
            <path
              d="M2 712.484V239.738C2 108.183 108.646 1.53711 240.201 1.53711V1.53711C371.756 1.53711 478.402 108.183 478.402 239.738V712.484"
              stroke="url(#paint0_linear_1_315)"
              stroke-width="2.08948"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1_315"
                x1="240.201"
                y1="1.53711"
                x2="240.201"
                y2="712.484"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#A7327A" />
                <stop offset="1" stop-color="#3B90FB" />
              </linearGradient>
            </defs>
          </svg>
          <img
            loading="lazy"
            alt="romantic-main"
            src={romantic1}
            className={style.romantic1}
          />
        </div>
 */
