import user1 from "../assets/user-01.svg";
import user2 from "../assets/user-02.svg";
import user3 from "../assets/user-03.svg";
import user4 from "../assets/user-04.svg";
import user5 from "../assets/user-05.svg";
import partnerImg from "../assets/partner.png";
import partnerImg2 from "../assets/image 912 (2).png";
import cat1 from "../assets/cat-01.png";
import cat2 from "../assets/cat-02.png";
import cat3 from "../assets/cat-03.png";
import cat4 from "../assets/cat-04.png";
import pro1 from "../assets/promo-01.jpeg";
import pro2 from "../assets/promo-02.jpeg";
import pack1 from "../assets/pack-01.png";
import pack2 from "../assets/pack-02.png";
import pack3 from "../assets/pack-03.png";
import country1 from "../assets/country-01.svg";
import country2 from "../assets/country-02.svg";
import country3 from "../assets/country-03.svg";
import linkedin from "../assets/linkedin.svg";
import twitter from "../assets/twitter.svg";
import messenger from "../assets/messenger.svg";
import two from "../assets/twoo.svg";
import book1 from "../assets/book-01.svg";
import book2 from "../assets/book-02.svg";
import book3 from "../assets/book-03.svg";
import bookingDetails from "../assets/bookingDetails.png";
import ongoing from "../assets/onGoing.png";
import megz from "../assets/download.png";
import rev3 from "../assets/imgs.png";
import revImg from "../assets/rev-02.svg";
import travel1 from "../assets/travel-01.jpeg";
import travel2 from "../assets/travel-02.jpeg";
import provide1 from "../assets/provide-01.png";
import provide2 from "../assets/provide-02.png";
import provide3 from "../assets/provide-03.png";
import provide4 from "../assets/provide-04.png";
export const navlinks = [
  {
    path: "#",
    title: "home",
  },
  {
    path: "#about",
    title: "about",
  },
  {
    path: "#services",
    title: "services",
  },
  {
    path: "#upcoming",
    title: "Upcomming Packages",
  },
];
export const users = [user1, user2, user3, user4, user5, user2];
export const partner = [
  partnerImg,
  partnerImg2,
  partnerImg,
  partnerImg2,
  partnerImg,
  partnerImg2,
  partnerImg,
  partnerImg2,
  partnerImg,
  partnerImg2,
  partnerImg,
  partnerImg2,
  partnerImg,
];
export const categories = [
  {
    id: 1,
    img: cat1,
    title: "Guided Tours",
    desc: "sunt qui repellat saepe quo velit aperiam id aliquam placeat.",
    isSpecial: false,
  },
  {
    id: 2,
    img: cat2,
    title: "Best Flights Options",
    desc: "sunt qui repellat saepe quo velit aperiam id aliquam placeat.",
    isSpecial: true,
  },
  {
    id: 3,
    img: cat3,
    title: "Religious Tours",
    desc: "sunt qui repellat saepe quo velit aperiam id aliquam placeat.",
    isSpecial: false,
  },
  {
    id: 4,
    img: cat4,
    title: "Medical insurance",
    desc: "sunt qui repellat saepe quo velit aperiam id aliquam placeat.",
    isSpecial: false,
  },
];

export const promotions = [
  {
    img: travel1,
    title: "Promotion",
    desc: "Explore Nature",
  },
  {
    img: travel2,
    title: "Promotion",
    desc: "Explore cities",
  },
];
export const packages = [
  {
    id: 1,
    img: pack1,
    city: country1,
    days: 5,
    people: 20,
    title: "Switzerland",
    country: "europe",
    orignalPrice: "1200$",
    price: "1000$",
    desc: "Nam exercitationem commodi et ducimus quia in dolore animi sit mollitia amet id quod eligendi. Et labore harum non nobis ipsum eum molestias mollitia et corporis praesentium a laudantium internos.",
  },
  {
    id: 2,
    img: pack2,
    city: country2,
    days: 8,
    people: 30,
    title: "Amazon",
    country: "Brazil",
    orignalPrice: "1250$",
    price: "1020$",
    desc: "Nam exercitationem commodi et ducimus quia in dolore animi sit mollitia amet id quod eligendi. Et labore harum non nobis ipsum eum molestias mollitia et corporis praesentium a laudantium internos.",
  },
  {
    id: 3,
    img: pack3,
    city: country3,
    days: 3,
    people: 10,
    title: "Amazon",
    country: "Brazil",
    orignalPrice: "1250$",
    price: "1020$",
    desc: "Nam exercitationem commodi et ducimus quia in dolore animi sit mollitia amet id quod eligendi. Et labore harum non nobis ipsum eum molestias mollitia et corporis praesentium a laudantium internos.",
  },
];
export const social = [
  {
    img: linkedin,
    path: "https://www.linkedin.com",
  },
  {
    img: messenger,
    path: "https://facebook.com",
  },
  {
    img: twitter,
    path: "https://www.twitter.com",
  },
  {
    img: two,
    path: "https://www.twoo.com",
  },
];
export const company = [
  {
    title: "about us",
    path: "#about",
  },
  {
    title: "careers",
    path: "#about",
  },
  {
    title: "blogs",
    path: "#about",
  },
  {
    title: "pricing",
    path: "#about",
  },
];
export const dest = [
  {
    title: "maldives",
    path: "",
  },
  {
    title: "Los Angelas",
    path: "",
  },
  {
    title: "Las Vegas",
    path: "",
  },
  {
    title: "Torronto",
    path: "",
  },
];
export const bookings = [
  {
    img: book1,
    title: "Choose Destination",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, tortor tempus.",
  },
  {
    img: book2,
    title: "Check Availability",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, tortor tempus.",
  },
  {
    img: book3,
    title: "Let’s Go",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, tortor tempus.",
  },
];
export const bookingbox = {
  img: bookingDetails,
  title: "Trip to Hawaii",
  date: "14-29 June",
  by: "by JR Martinax",
  ongoingImg: ongoing,
  ongoingTitle: "Trip to rome",
};
export const revs = [
  {
    img: megz,

    name: "Christine Beckam - Designer",
    desc: "Vel officiis dolor ea illo aut eligendi ullam non laudantium magnam et recusandae molestiae sit iure unde aut voluptate quaerat. Id sunt provident quo possimus impedit vel doloremque obcaecati qui ullam consectetur et ipsum omnis.",
  },
  {
    img: revImg,
    name: "Christine Beckam - Designer",
    desc: "Vel officiis dolor ea illo aut eligendi ullam non laudantium magnam et recusandae molestiae sit iure unde aut voluptate quaerat. Id sunt provident quo possimus impedit vel doloremque obcaecati qui ullam consectetur et ipsum omnis.",
  },
  {
    img: rev3,
    name: "Christine Beckam - Designer",
    desc: "Vel officiis dolor ea illo aut eligendi ullam non laudantium magnam et recusandae molestiae sit iure unde aut voluptate quaerat. Id sunt provident quo possimus impedit vel doloremque obcaecati qui ullam consectetur et ipsum omnis.",
  },
];
export const provide = [
  {
    img: provide1,
    price: "600$",
  },
  {
    img: provide2,
    price: "700$",
  },
  {
    img: provide3,
    price: "800$",
  },
  {
    img: provide4,
    price: "900$",
  },
];
