import React from "react";
import style from "./bookings.module.css";
import plane from "../../../assets/bookings.png";
const Bookings = ({ bookingDetails, bookingbox }) => {
  return (
    <div className="container my-5 pt-4">
      <div className="row align-items-center">
        <div className="col-12 col-md-6 mb-3 mb-md-0 ">
          <p className={`m-0 p-0 mb-1 ${style.intro}`}>Fast & Easy</p>
          <p className={`m-0 p-0 mb-4 ${style.desc}`}>
            Get Your Favourite Resort Bookings
          </p>
          {bookingDetails.map((item, index) => (
            <div key={index} className="d-flex align-items-center gap-3 mb-4">
              <img
                loading="lazy"
                alt="icon-booking"
                src={item.img}
                className={style.bookingIcon}
              />
              <div className="m-0 p-0">
                <p className={`m-0 p-0 mb-2 ${style.bookingTitle}`}>
                  {item.title}
                </p>
                <p className={`m-0 p-0   ${style.bookingDesc}`}>{item.desc}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="col-11 col-md-6  mb-md-0">
          <img
            loading="lazy"
            src={plane}
            alt="plan/img"
            className={style.booking}
          />
        </div>
      </div>
    </div>
  );
};

export default Bookings;
/**
 *    <div className="col-12 col-md-6 mb-3 mb-md-0 position-relative">
        
          <div className={`p-2 ${style.mainBox}`}>
            <img
              loading="lazy"
              alt="mainbox-img"
              src={bookingbox.img}
              className={style.mainImg}
            />
            <p className={`m-0 p-0 mb-2 ${style.mainboxTitle}`}>
              {bookingbox.title}
            </p>
            <p className={`m-0 p-0 mb-3 ${style.date}`}>
              {bookingbox.date} | {bookingbox.by}
            </p>
            <div className="d-flex align-items-center gap-5 mb-4">
              <div className={style.svgContainer}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <g clip-path="url(#clip0_1_191)">
                    <path
                      d="M13.6544 0.150827C13.2856 0.0428744 4.26249 -0.855475 1.09366 3.42567C-0.309786 5.32223 -0.330868 7.9221 1.02333 11.1525C0.67981 11.8655 0.379084 12.6166 0.134002 13.4091C0.0594904 13.6499 0.194268 13.9052 0.43481 13.9795C0.479654 13.9934 0.525072 14 0.569915 14C0.764384 14 0.944934 13.8744 1.0052 13.6787C3.42041 5.87028 11.2693 2.09267 13.6763 1.13835C13.9105 1.0454 14.2236 0.317405 13.6544 0.150827Z"
                      fill="#84829A"
                    />
                    <path
                      d="M2.47188 12.1297C3.77828 12.6778 5.08449 12.9587 6.29918 12.9587C7.39098 12.9587 8.40918 12.7342 9.28658 12.279C11.0524 11.3629 12.1315 9.61075 12.407 7.21126C12.6878 4.76584 13.0373 3.2341 13.3288 2.29706C10.7335 3.49422 5.16873 6.59734 2.47188 12.1297Z"
                      fill="#84829A"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_191">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className={style.svgContainer}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <g clip-path="url(#clip0_1_205)">
                    <path
                      d="M13.5009 3.56123e-08C13.4232 -2.92608e-05 13.3466 0.0180173 13.2771 0.0527336L9.48821 1.94771L5.20378 0.0432416C5.19678 0.0402533 5.18878 0.039726 5.18128 0.0367378C5.16201 0.0295308 5.14229 0.0235251 5.12228 0.0187497C5.10239 0.0133885 5.08217 0.00919911 5.06178 0.00624017C5.02118 0.00290038 4.98037 0.00290038 4.93979 0.00624017C4.9194 0.00919911 4.89919 0.0133885 4.8793 0.0187497C4.85929 0.0235251 4.83957 0.0295308 4.82029 0.0367378C4.81279 0.039726 4.80479 0.040224 4.79779 0.0432416L0.297862 2.04322C0.11722 2.12343 0.000825201 2.30255 0.000854498 2.50019V13.5C0.000942387 13.6692 0.0865172 13.8268 0.228341 13.919C0.370195 14.0114 0.549108 14.0257 0.703822 13.957L5.00076 12.047L9.29769 13.957C9.30519 13.96 9.31319 13.957 9.32069 13.9615C9.44174 14.0157 9.5807 14.0126 9.69917 13.953C9.70717 13.9496 9.71616 13.953 9.72416 13.9475L13.7241 11.9476C13.8936 11.8629 14.0007 11.6896 14.0006 11.5V0.500237C14.0008 0.224088 13.777 0.000117221 13.5009 3.56123e-08ZM4.50079 11.1751L1.00083 12.7305V2.8252L4.50079 1.26971V11.1751ZM9.00072 12.7305L5.50076 11.1751V1.26971L9.00072 2.82517V12.7305ZM13.0006 11.1911L10.0007 12.691V2.80918L13.0006 1.3092V11.1911Z"
                      fill="#84829A"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_205">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className={style.svgContainer}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M12.3433 1.24215L6.02583 7.58298C5.95341 7.54102 5.87725 7.50587 5.79833 7.47798L1.6275 6.06631C1.50136 6.0347 1.38941 5.96185 1.30942 5.85934C1.22942 5.75682 1.18597 5.63051 1.18597 5.50048C1.18597 5.37045 1.22942 5.24414 1.30942 5.14162C1.38941 5.03911 1.50136 4.96626 1.6275 4.93465L11.9758 1.25381C12.0435 1.23085 12.1144 1.21902 12.1858 1.21881C12.2393 1.21795 12.2925 1.22583 12.3433 1.24215Z"
                    fill="#84829A"
                  />
                  <path
                    d="M12.7459 2.02375L9.06503 12.3838C9.02444 12.5045 8.94545 12.6086 8.84012 12.6803C8.73478 12.7519 8.6089 12.7871 8.4817 12.7804C8.35303 12.7863 8.22602 12.7494 8.1205 12.6756C8.01498 12.6017 7.93687 12.495 7.89836 12.3721L6.51003 8.20125C6.48214 8.12233 6.44699 8.04617 6.40503 7.97375L12.7575 1.65625C12.7934 1.7768 12.7893 1.90571 12.7459 2.02375Z"
                    fill="#84829A"
                  />
                </svg>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M0.266665 15.4667H1.86667V6.66667C1.86667 6.5194 1.98607 6.4 2.13333 6.4H5.86667V0.266667C5.86667 0.1194 5.98607 0 6.13333 0H13.0667C13.2139 0 13.3333 0.1194 13.3333 0.266667V15.4667H15.7333C15.8806 15.4667 16 15.5861 16 15.7333C16 15.8806 15.8806 16 15.7333 16H0.266665C0.119399 16 0 15.8806 0 15.7333C0 15.5861 0.119399 15.4667 0.266665 15.4667ZM10.4 15.4667H11.4667V13.0667C11.4667 12.9194 11.3473 12.8 11.2 12.8H10.6667C10.5194 12.8 10.4 12.9194 10.4 13.0667V15.4667ZM9.33333 6.66667V15.4667H9.86667V13.0667C9.86667 12.6248 10.2248 12.2667 10.6667 12.2667H11.2C11.6418 12.2667 12 12.6248 12 13.0667V15.4667H12.8V0.533333H6.4V6.4H9.06667C9.21393 6.4 9.33333 6.5194 9.33333 6.66667ZM2.4 15.4667H8.8V6.93333H2.4V15.4667Z"
                    fill="#84829A"
                  />
                  <path
                    d="M7.46665 3.7334H9.06665C9.21391 3.7334 9.33331 3.8528 9.33331 4.00007V5.60007C9.33331 5.74733 9.21391 5.86673 9.06665 5.86673H7.46665C7.31938 5.86673 7.19998 5.74733 7.19998 5.60007V4.00007C7.19998 3.8528 7.31938 3.7334 7.46665 3.7334ZM7.73331 5.3334H8.79998V4.26673H7.73331V5.3334Z"
                    fill="#84829A"
                  />
                  <path
                    d="M7.46658 1.06641H9.06659C9.21385 1.06641 9.33325 1.18581 9.33325 1.33307V2.93307C9.33325 3.08034 9.21385 3.19974 9.06659 3.19974H7.46658C7.31932 3.19974 7.19992 3.08034 7.19992 2.93307V1.33307C7.19992 1.18581 7.31932 1.06641 7.46658 1.06641ZM7.73325 2.66641H8.79992V1.59974H7.73325V2.66641Z"
                    fill="#84829A"
                  />
                  <path
                    d="M10.1333 3.7334H11.7333C11.8806 3.7334 12 3.8528 12 4.00007V5.60007C12 5.74733 11.8806 5.86673 11.7333 5.86673H10.1333C9.98607 5.86673 9.86667 5.74733 9.86667 5.60007V4.00007C9.86667 3.8528 9.98607 3.7334 10.1333 3.7334ZM10.4 5.3334H11.4667V4.26673H10.4V5.3334Z"
                    fill="#84829A"
                  />
                  <path
                    d="M10.1333 1.06641H11.7333C11.8806 1.06641 12 1.18581 12 1.33307V2.93307C12 3.08034 11.8806 3.19974 11.7333 3.19974H10.1333C9.98607 3.19974 9.86667 3.08034 9.86667 2.93307V1.33307C9.86667 1.18581 9.98607 1.06641 10.1333 1.06641ZM10.4 2.66641H11.4667V1.59974H10.4V2.66641Z"
                    fill="#84829A"
                  />
                  <path
                    d="M10.1333 6.40039H11.7333C11.8806 6.40039 12 6.51979 12 6.66706V8.26706C12 8.41432 11.8806 8.53372 11.7333 8.53372H10.1333C9.98607 8.53372 9.86667 8.41432 9.86667 8.26706V6.66706C9.86667 6.51979 9.98607 6.40039 10.1333 6.40039ZM10.4 8.00039H11.4667V6.93372H10.4V8.00039Z"
                    fill="#84829A"
                  />
                  <path
                    d="M10.1333 9.06641H11.7333C11.8806 9.06641 12 9.18581 12 9.33307V10.9331C12 11.0803 11.8806 11.1997 11.7333 11.1997H10.1333C9.98607 11.1997 9.86667 11.0803 9.86667 10.9331V9.33307C9.86667 9.18581 9.98607 9.06641 10.1333 9.06641ZM10.4 10.6664H11.4667V9.59974H10.4V10.6664Z"
                    fill="#84829A"
                  />
                  <path
                    d="M5.06659 12.2661H3.46659C3.31932 12.2661 3.19992 12.1467 3.19992 11.9995V10.3995C3.19992 10.2522 3.31932 10.1328 3.46659 10.1328H5.06659C5.21385 10.1328 5.33325 10.2522 5.33325 10.3995V11.9995C5.33325 12.1467 5.21385 12.2661 5.06659 12.2661ZM4.79992 10.6661H3.73325V11.7328H4.79992V10.6661Z"
                    fill="#84829A"
                  />
                  <path
                    d="M5.06659 9.60013H3.46659C3.31932 9.60013 3.19992 9.48073 3.19992 9.33346V7.73346C3.19992 7.5862 3.31932 7.4668 3.46659 7.4668H5.06659C5.21385 7.4668 5.33325 7.5862 5.33325 7.73346V9.33346C5.33325 9.48073 5.21385 9.60013 5.06659 9.60013ZM4.79992 8.00013H3.73325V9.0668H4.79992V8.00013Z"
                    fill="#84829A"
                  />
                  <path
                    d="M5.06659 14.9331H3.46659C3.31932 14.9331 3.19992 14.8137 3.19992 14.6665V13.0665C3.19992 12.9192 3.31932 12.7998 3.46659 12.7998H5.06659C5.21385 12.7998 5.33325 12.9192 5.33325 13.0665V14.6665C5.33325 14.8137 5.21385 14.9331 5.06659 14.9331ZM4.79992 13.3331H3.73325V14.3998H4.79992V13.3331Z"
                    fill="#84829A"
                  />
                  <path
                    d="M7.73333 12.2661H6.13333C5.98607 12.2661 5.86667 12.1467 5.86667 11.9995V10.3995C5.86667 10.2522 5.98607 10.1328 6.13333 10.1328H7.73333C7.8806 10.1328 8 10.2522 8 10.3995V11.9995C8 12.1467 7.8806 12.2661 7.73333 12.2661ZM7.46667 10.6661H6.4V11.7328H7.46667V10.6661Z"
                    fill="#84829A"
                  />
                  <path
                    d="M7.73333 9.60013H6.13333C5.98607 9.60013 5.86667 9.48073 5.86667 9.33346V7.73346C5.86667 7.5862 5.98607 7.4668 6.13333 7.4668H7.73333C7.8806 7.4668 8 7.5862 8 7.73346V9.33346C8 9.48073 7.8806 9.60013 7.73333 9.60013ZM7.46667 8.00013H6.4V9.0668H7.46667V8.00013Z"
                    fill="#84829A"
                  />
                  <path
                    d="M7.73333 14.9331H6.13333C5.98607 14.9331 5.86667 14.8137 5.86667 14.6665V13.0665C5.86667 12.9192 5.98607 12.7998 6.13333 12.7998H7.73333C7.8806 12.7998 8 12.9192 8 13.0665V14.6665C8 14.8137 7.8806 14.9331 7.73333 14.9331ZM7.46667 13.3331H6.4V14.3998H7.46667V13.3331Z"
                    fill="#84829A"
                  />
                </svg>
                <p className={`m-0 p-0 ${style.view}`}>
                  60 people are interested
                </p>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M18.3644 2.45408C17.33 1.34216 15.8804 0.709493 14.3617 0.707148C12.8417 0.708907 11.3906 1.34124 10.3546 2.45337L10.0015 2.82649L9.64836 2.45337C7.59251 0.240709 4.13215 0.11364 1.91953 2.16949C1.82148 2.26063 1.72679 2.35528 1.63565 2.45337C-0.545218 4.8057 -0.545218 8.44113 1.63565 10.7935L9.48255 19.0685C9.75403 19.3551 10.2064 19.3674 10.493 19.0959C10.5024 19.087 10.5115 19.0779 10.5204 19.0685L18.3645 10.7935C20.5452 8.44138 20.5452 4.80616 18.3644 2.45408ZM17.3302 9.8092H17.3294L10.0015 17.5382L2.67282 9.8092C1.00676 8.0118 1.00676 5.23432 2.67282 3.43692C4.1858 1.79708 6.74172 1.69426 8.38156 3.20724C8.46118 3.2807 8.53778 3.35731 8.61124 3.43692L9.48255 4.35614C9.76969 4.64144 10.2333 4.64144 10.5204 4.35614L11.3917 3.43763C12.9047 1.79779 15.4606 1.69497 17.1005 3.20795C17.1801 3.28141 17.2567 3.35802 17.3302 3.43763C19.0107 5.23788 19.023 8.02043 17.3302 9.8092Z"
                  fill="#DF6951"
                />
              </svg>
            </div>
            <div
              className={`p-3 ${style.subBox} d-flex align-items-center gap-3 flex-column flex-md-row`}
            >
              <img
                loading="lazy"
                alt="ongoing"
                src={bookingbox.ongoingImg}
                className={style.ongoingImg}
              />
              <div>
                <p className={`m-0 p-0 mb-1 ${style.ongoing}`}>ongoing</p>
                <p className={`m-0 p-0 mb-2 ${style.trip}`}>
                  {bookingbox.ongoingTitle}
                </p>
                <span className={style.percent}>40%</span>
                <span className={style.comp}>complete</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="156"
                  height="5"
                  viewBox="0 0 156 5"
                  fill="none"
                >
                  <rect width="156" height="5" rx="2.5" fill="#F5F5F5" />
                  <rect width="73" height="5" rx="2.5" fill="#DF6951" />
                </svg>
              </div>
            </div>
          </div>
          <div className={style.circule}></div>
          <div className={style.plusConainer}>
            <div>
              <p className={`m-0 p-0 mb-1 ${style.plus} ${style.active}`}>+</p>
              <p className={`m-0 p-0 mb-1 ${style.plus} `}>+</p>
              <p className={`m-0 p-0 mb-1 ${style.plus} `}>+</p>
              <p className={`m-0 p-0 mb-1 ${style.plus}`}>+</p>
              <p className={`m-0 p-0 mb-1 ${style.plus} `}>+</p>
            </div>
            <div>
              <p className={`m-0 p-0 mb-1 ${style.plus} `}>+</p>
              <p className={`m-0 p-0 mb-1 ${style.plus} `}>+</p>
              <p className={`m-0 p-0 mb-1 ${style.plus} `}>+</p>
              <p className={`m-0 p-0 mb-1 ${style.plus}`}>+</p>
              <p className={`m-0 p-0 mb-1 ${style.plus} ${style.active}`}>+</p>
            </div>
            <div>
              <p className={`m-0 p-0 mb-1 ${style.plus} `}>+</p>
              <p className={`m-0 p-0 mb-1 ${style.plus} `}>+</p>
              <p className={`m-0 p-0 mb-1 ${style.plus} `}>+</p>
              <p className={`m-0 p-0 mb-1 ${style.plus} ${style.active}`}>+</p>
              <p className={`m-0 p-0 mb-1 ${style.plus} ${style.active}`}>+</p>
            </div>
            <div>
              <p className={`m-0 p-0 mb-1 ${style.plus} `}>+</p>
              <p className={`m-0 p-0 mb-1 ${style.plus} `}>+</p>
              <p className={`m-0 p-0 mb-1 ${style.plus}`}>+</p>
              <p className={`m-0 p-0 mb-1 ${style.plus} ${style.active}`}>+</p>
              <p className={`m-0 p-0 mb-1 ${style.plus} ${style.active}`}>+</p>
            </div>
          </div>
          <img
            loading="lazy"
            alt="plan/img"
            className={style.plan}
            src={plane}
          />
        </div>
 */
