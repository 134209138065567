import React from "react";
import style from "./category.module.css";
const Category = ({ data }) => {
  return (
    <div className="container my-5 pt-4">
      <p className={`text-center m-0 p-0  ${style.title}`}>category</p>
      <p className={`text-center m-0 p-0 mb-2 vol ${style.desc}`}>
        We Offer Best Services
      </p>
      <div className="row justify-content-center ">
        {data.map((item, index) => (
          <div
            key={index}
            className="col-12 col-md-6 col-lg-3 position-relative "
          >
            <div className="z-3 p-0 m-0 position-relative ">
              <div className={`${style.mainContainer}`}>
                <div className="p-3">
                  <div
                    className={`d-flex justify-content-center ${style.imgContainer}`}
                  >
                    <img
                      loading="lazy"
                      alt="category-img"
                      src={item.img}
                      className={style.mainImg}
                    />
                  </div>
                  <p className={`mb-2 mt-0 mx-0 p-0 ${style.categoryTitle}`}>
                    {item.title}
                  </p>
                  <p className={`mb-2 mt-0 mx-0 p-0 ${style.categoryDesc}`}>
                    {item.desc}
                  </p>
                </div>
              </div>
            </div>
            {item.isSpecial && <div className={`z-2 ${style.special}`}></div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Category;
