import React from "react";
import Hero from "../components/home/hero/Hero";
import Partners from "../components/home/partners/Partners";
import Category from "../components/home/category/Category";
import Banner from "../components/home/banner/Banner";
import Promotions from "../components/home/promotion/Promotions";
import Packages from "../components/home/packages/Packages";
import Romantic from "../components/home/romantic/Romantic";
import Bookings from "../components/home/bookings/Bookings";
import Rev from "../components/home/rev/Rev";
import Provide from "../components/home/provide/Provide";

const Home = ({
  users,
  partner,
  categories,
  promotions,
  packages,
  bookings,
  bookingbox,
  revs,
  provide,
}) => {
  return (
    <div>
      <Hero users={users} />
      <Partners data={partner} />
      <Category data={categories} />
      <Romantic />
      <Bookings bookingDetails={bookings} bookingbox={bookingbox} />
      <Banner />
      <Provide data={provide} />
      <Promotions data={promotions} />
      <Packages data={packages} />
      <Rev data={revs} />
    </div>
  );
};

export default Home;
/**
 *  
      
    
     
     
     
    
     
 * 
 */
