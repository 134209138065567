import React from "react";
import style from "./provide.module.css";
const Provide = ({ data }) => {
  return (
    <div className="container my-5 pt-4">
      <div className={style.mainDiv}>
        <p className={`${style.intro} m-0 mb-1 p-0`}>Promotion</p>
        <p className={`${style.title} m-0 p-0 mb-2 mb-md-4 vol `}>
          We Provide You Best Europe Sightseeing Tours
        </p>
        <p className={`mb-5 ${style.desc}`}>
          Et labore harum non nobis ipsum eum molestias mollitia et corporis
          praesentium a laudantium internos. Non quis eius quo eligendi corrupti
          et fugiat nulla qui soluta recusandae in maxime quasi aut ducimus
          illum aut optio quibusdam!
        </p>
        <div className="mt-5 pt-5 row gap-4 justify-content-center justify-content-md-start">
          {data.map((item, index) => (
            <div
              key={index}
              className={`m-0 p-0 mt-md-5 position-relative col-5 col-md-4 col-lg-2  ${style.imgContainer}`}
            >
              <img loading="lazy" alt="trip" src={item.img} />
              <div className={style.overlay}>
                <p className={`${style.priceContainer} m-0 p-1`}>
                  {item.price}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Provide;
