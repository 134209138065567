import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  navlinks,
  users,
  partner,
  categories,
  promotions,
  packages,
  social,
  company,
  dest,
  bookings,
  bookingbox,
  revs,
  provide,
} from "./fakers/data";
import Home from "./pages/Home";
import Meta from "./components/utils/meta/Meta";
import Nav from "./components/layouts/nav/Nav";
import Footer from "./components/layouts/footer/Footer";
const App = () => {
  return (
    <Router>
      <Meta />
      <Nav data={navlinks} />
      <Routes>
        <Route
          path="/"
          element={
            <Home
              users={users}
              partner={partner}
              categories={categories}
              promotions={promotions}
              packages={packages}
              bookings={bookings}
              bookingbox={bookingbox}
              revs={revs}
              provide={provide}
            />
          }
        />
      </Routes>
      <Footer social={social} company={company} dest={dest} />
    </Router>
  );
};

export default App;
