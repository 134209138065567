import React from "react";
import style from "./footer.module.css";
import { Link } from "react-router-dom";
const Footer = ({ social, company, dest }) => {
  return (
    <div className={style.mainDiv}>
      <div className="pt-3 container">
        <div className="row justify-content-center">
          <div className="text-center text-md-start col-12  col-md-3 mb-4 mb-md-0">
            <div className="position-relative mb-2">
              <p className={`m-0 p-0 vol ${style.logo}`}>travel</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="99"
                height="39"
                viewBox="0 0 99 39"
                fill="none"
                className={style.arrow}
              >
                <g clip-path="url(#clip0_1_64)">
                  <path
                    d="M-7.17494e-06 15.123C5.57704 23.1055 29.8414 31.0512 46.1621 30.5525C63.8031 30.0535 73.4636 26.5629 92.2711 13.7275C92.2165 15.1328 92.0508 16.2765 92.0776 17.3197C92.0583 18.5099 92.0498 19.7683 92.3016 20.9154C92.3557 21.256 93.0632 21.7721 93.4807 21.7756C93.8982 21.779 94.5002 21.1945 94.5816 20.8324C95.2821 17.3691 95.847 13.9274 96.4688 10.4067C96.6345 9.26304 96.0979 8.51032 94.8916 8.35287C90.3127 7.96322 85.723 7.50545 81.0872 7.19468C80.038 7.15192 78.8613 7.61826 77.5057 7.83369C79.0556 10.5902 81.4416 9.86186 83.3229 10.331C85.2829 10.8576 87.3242 11.022 89.8264 11.4623C84.8069 16.1707 79.4923 19.4595 73.6981 21.9166C52.4896 31.0134 31.4197 29.6132 10.6747 20.2004C7.97482 18.9535 5.43502 17.4018 2.79212 16.0759C1.99242 15.8539 1.26049 15.621 -7.17494e-06 15.123Z"
                    fill="#DF6951"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_64">
                    <rect
                      width="96.1304"
                      height="23.346"
                      fill="white"
                      transform="matrix(0.987606 -0.156951 -0.156951 -0.987606 3.66418 38.1797)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <p className={`m-0 p-0  ${style.desc}`}>
              Travel helps companies manage payments easily.
            </p>
            <div className="m-0 p-0 d-flex align-items-center justify-content-center justify-content-md-start gap-3 mt-4">
              {social.map((item, index) => (
                <a
                  className="d-inline-block m-0 p-0"
                  key={index}
                  href={item.path}
                  target="_blank"
                >
                  <img
                    loading="lazy"
                    alt="social/img"
                    src={item.img}
                    className={style.social}
                  />
                </a>
              ))}
            </div>
          </div>
          <div className="text-center text-md-start col-12 col-md-3 mb-4 mb-md-0">
            <p className={`m-0 p-0 mb-3 vol ${style.footerTitle}`}>company</p>
            <ul className="m-0 p-0">
              {company.map((item, index) => (
                <li className="mb-2" key={index}>
                  <Link className={style.link} to={item.path}>
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="text-center text-md-start col-12 col-md-3 mb-4 mb-md-0">
            <p className={`m-0 p-0 mb-3 vol ${style.footerTitle}`}>
              Destination
            </p>
            <ul className="m-0 p-0">
              {dest.map((item, index) => (
                <li className="mb-2" key={index}>
                  <Link className={style.link} to={item.path}>
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="text-center text-md-start col-12 col-md-3 mb-4 mb-md-0">
            <p className={`m-0 p-0 mb-3 vol ${style.footerTitle}`}>
              join our news letters
            </p>
            <div className="m-0  p-0 d-flex flex-column gap-2 gap-md-0 flex-md-row align-items-center">
              <input
                type="email"
                placeholder="enter your email"
                className={style.inp}
              />
              <button className={`d-inline-block p-2 ${style.btn}`}>
                subscripe
              </button>
            </div>
            <p className={`p-0 m-0 mt-3 ${style.subDesc}`}>
              * Will send you weekly updates for your better tour packages.
            </p>
          </div>
        </div>
      </div>
      <div className={`m-0 p-0 ${style.line}`}></div>
      <div className="d-flex align-items-center justify-content-center">
        <p className={`m-0 p-0 container my-4 text-center ${style.copyRight}`}>
          Copyright &#169; Xpro 2022. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
/**
 * <div className="m-0 p-0 mt-5 pt-4">
      <div className="container">
        <div className="row">
          <div className="col-6 mb-4 col-md-3 position-relative d-flex flex-column gap-2">
           
            
           
         
          </div>
          <div className="mb-4 col-6 col-md-3">
           
           
          </div>
          <div className="mb-4 col-6 col-md-3">
           
          </div>
          <div className="mb-4 col-6 col-md-3">
           
           
           
          </div>
        </div>
      </div>
     
    </div>
 * 
 */
