import React, { useState, useEffect } from "react";
import style from "./nav.module.css";
import { Link } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
const Nav = ({ data }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [isBg, setIsBg] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setIsBg(true);
      } else {
        setIsBg(false);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div className={`${style.navContainer} ${isBg ? style.bg : style.nonBg}`}>
        <div className="container py-3 d-flex align-items-center justify-content-between">
          <div className="position-relative">
            <h2
              className={`${style.logo} ${
                isBg ? "text-black" : "text-white"
              } vol`}
            >
              travel
            </h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="99"
              height="39"
              viewBox="0 0 99 39"
              fill="none"
              className={style.svg}
            >
              <g clip-path="url(#clip0_1_400)">
                <path
                  d="M-7.17494e-06 15.123C5.57704 23.1055 29.8414 31.0512 46.1621 30.5525C63.8031 30.0535 73.4636 26.5629 92.2711 13.7275C92.2165 15.1328 92.0508 16.2765 92.0776 17.3197C92.0583 18.5099 92.0498 19.7683 92.3016 20.9154C92.3557 21.256 93.0632 21.7721 93.4807 21.7756C93.8982 21.779 94.5002 21.1945 94.5816 20.8324C95.2821 17.3691 95.847 13.9274 96.4688 10.4067C96.6345 9.26304 96.0979 8.51032 94.8916 8.35287C90.3127 7.96322 85.723 7.50545 81.0872 7.19468C80.038 7.15192 78.8613 7.61826 77.5057 7.83369C79.0556 10.5902 81.4416 9.86186 83.3229 10.331C85.2829 10.8576 87.3242 11.022 89.8264 11.4623C84.8069 16.1707 79.4923 19.4595 73.6981 21.9166C52.4896 31.0134 31.4197 29.6132 10.6747 20.2004C7.97482 18.9535 5.43502 17.4018 2.79212 16.0759C1.99242 15.8539 1.26049 15.621 -7.17494e-06 15.123Z"
                  fill="#DF6951"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_400">
                  <rect
                    width="96.1304"
                    height="23.346"
                    fill="white"
                    transform="matrix(0.987606 -0.156951 -0.156951 -0.987606 3.66418 38.1797)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <ul className="d-none d-md-flex m-0 p-0  align-items-center gap-5">
            {data.map((item, index) =>
              item.title === "services" ? (
                <li class={`dropdown pointer ${style.nav} `}>
                  <a
                    to={item.path}
                    className={`dropdown-toggle ${
                      isBg ? "text-black" : "text-white"
                    } ${style.links}`}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {item.title}
                  </a>
                  <ul class={`dropdown-menu ${isBg ? "scroll" : null}`}>
                    <li>
                      <a class="dropdown-item" href="#">
                        Honeymoon Packages
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Tours Packages
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Musical Events
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Build Package
                      </a>
                    </li>
                  </ul>
                </li>
              ) : (
                <li key={index} className={`m-0 p-0 ${style.nav}`}>
                  <a
                    href={item.path}
                    className={`${isBg ? "text-black" : "text-white"} ${
                      style.links
                    }`}
                  >
                    {item.title}
                  </a>
                </li>
              )
            )}
          </ul>
          <button
            className={`d-none d-md-flex align-items-center justify-content-center text-white ${style.btn}`}
          >
            get in touch
          </button>
          <div className="d-md-none">
            <AiOutlineMenu
              onClick={() => setShowSidebar(true)}
              className={` pointer ${isBg ? "text-black" : "text-white"}`}
              size={30}
            />
          </div>
        </div>
        <div
          className={`${style.sidebar} ${
            showSidebar ? style.show : style.hide
          } ${isBg ? style.bg : null}`}
        >
          <div className="p-3">
            <AiOutlineClose
              size={20}
              className="pointer"
              onClick={() => setShowSidebar(false)}
            />
            <ul className="m-0 p-0 mt-5 pt-3">
              {data.map((item, index) =>
                item.title === "services" ? (
                  <li class="dropdown mb-3 text-white">
                    <Link
                      className={`dropdown-toggle text-white`}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {item.title}
                    </Link>
                    <ul class="dropdown-menu">
                      <li>
                        <a class="dropdown-item" href="#">
                          Honeymoon Packages
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Tours Packages
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Musical Events
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Build Package
                        </a>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <li key={index} className="m-0 p-0 mb-3 ">
                    <Link to={item.path} className={` text-white`}>
                      {item.title}
                    </Link>
                  </li>
                )
              )}
            </ul>
            <button
              className={`my-4 align-items-center justify-content-center text-white ${style.btn}`}
            >
              get in touch
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
/**
 *   <div className={style.navContainer}>
        <div >
          
         
         
        </div>
      </div>
   
 * 
 */
/**
 *
 */
