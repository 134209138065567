import React from "react";
import style from "./packages.module.css";
const Packages = ({ data }) => {
  return (
    <div id="upcoming">
      <div className="container my-5 pt-4">
        <p className={`mb-1 mt-0 mx-0 p-0 ${style.intro} text-center`}>
          trendy
        </p>
        <p className={`${style.mainDesc} mt-0 mb-3 p-0 text-center`}>
          Our Trending Tour Packages
        </p>
        <div className="mt-5 row justify-content-center">
          {data.map((item, index) => (
            <div
              key={index}
              className={`${style.mainContainer} col-12 col-md-4 mb-3 mb-md-0`}
            >
              <div className={`m-0 p-0 vol mb-3 ${style.imgContainer}`}>
                <img
                  loading="lazy"
                  alt="country/img"
                  src={item.img}
                  className={style.mainImg}
                />
                <img
                  loading="lazy"
                  alt="country-img"
                  src={item.city}
                  className={style.city}
                />
              </div>

              <div className="px-3 mb-2 mt-4 d-flex align-items-center gap-5">
                <div className="d-flex align-items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19 4H17V3C17 2.73478 16.8946 2.48043 16.7071 2.29289C16.5196 2.10536 16.2652 2 16 2C15.7348 2 15.4804 2.10536 15.2929 2.29289C15.1054 2.48043 15 2.73478 15 3V4H9V3C9 2.73478 8.89464 2.48043 8.70711 2.29289C8.51957 2.10536 8.26522 2 8 2C7.73478 2 7.48043 2.10536 7.29289 2.29289C7.10536 2.48043 7 2.73478 7 3V4H5C4.20435 4 3.44129 4.31607 2.87868 4.87868C2.31607 5.44129 2 6.20435 2 7V19C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H19C19.7956 22 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 19V7C22 6.20435 21.6839 5.44129 21.1213 4.87868C20.5587 4.31607 19.7956 4 19 4ZM20 19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V12H20V19ZM20 10H4V7C4 6.73478 4.10536 6.48043 4.29289 6.29289C4.48043 6.10536 4.73478 6 5 6H7V7C7 7.26522 7.10536 7.51957 7.29289 7.70711C7.48043 7.89464 7.73478 8 8 8C8.26522 8 8.51957 7.89464 8.70711 7.70711C8.89464 7.51957 9 7.26522 9 7V6H15V7C15 7.26522 15.1054 7.51957 15.2929 7.70711C15.4804 7.89464 15.7348 8 16 8C16.2652 8 16.5196 7.89464 16.7071 7.70711C16.8946 7.51957 17 7.26522 17 7V6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V10Z"
                      fill="#7D7D7D"
                    />
                  </svg>
                  <p className={`${style.days} m-0 p-0`}>{item.days} days</p>
                </div>
                <div className="d-flex align-items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.99998 8C11.7 8 13.06 6.65 13.06 5C13.06 3.35 11.7 2 9.99998 2C8.29998 2 6.93998 3.35 6.93998 5C6.93998 6.65 8.29998 8 9.99998 8ZM9.99998 10C7.19998 10 4.93998 7.76 4.93998 5C4.93998 2.24 7.19998 0 9.99998 0C12.8 0 15.06 2.24 15.06 5C15.06 7.76 12.8 10 9.99998 10ZM2.99998 18H17V16.67C17 14.92 14.69 13.11 9.99998 13.11C5.30998 13.11 2.99998 14.92 2.99998 16.67V18ZM9.99998 11.11C16.66 11.11 19 14.44 19 16.67V20H0.999985V16.67C0.999985 14.44 3.33998 11.11 9.99998 11.11Z"
                      fill="#7D7D7D"
                    />
                  </svg>
                  <p className={`${style.days} m-0 p-0`}>
                    {item.people} people going
                  </p>
                </div>
              </div>
              <div className="m-0 py-0 px-3 mb-1 d-flex align-items-center justify-content-between">
                <p className={` m-0 p-0 ${style.title} `}>{item.title}</p>
                <div className="m-0 p-0 d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_1_519)">
                      <path
                        d="M3.612 15.443C3.226 15.641 2.788 15.294 2.866 14.851L3.696 10.121L0.172996 6.76501C-0.156004 6.45101 0.0149962 5.87701 0.455996 5.81501L5.354 5.11901L7.538 0.792012C7.735 0.402012 8.268 0.402012 8.465 0.792012L10.649 5.11901L15.547 5.81501C15.988 5.87701 16.159 6.45101 15.829 6.76501L12.307 10.121L13.137 14.851C13.215 15.294 12.777 15.641 12.391 15.443L8 13.187L3.611 15.443H3.612Z"
                        fill="#FFBA0A"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_519">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_1_519)">
                      <path
                        d="M3.612 15.443C3.226 15.641 2.788 15.294 2.866 14.851L3.696 10.121L0.172996 6.76501C-0.156004 6.45101 0.0149962 5.87701 0.455996 5.81501L5.354 5.11901L7.538 0.792012C7.735 0.402012 8.268 0.402012 8.465 0.792012L10.649 5.11901L15.547 5.81501C15.988 5.87701 16.159 6.45101 15.829 6.76501L12.307 10.121L13.137 14.851C13.215 15.294 12.777 15.641 12.391 15.443L8 13.187L3.611 15.443H3.612Z"
                        fill="#FFBA0A"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_519">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_1_519)">
                      <path
                        d="M3.612 15.443C3.226 15.641 2.788 15.294 2.866 14.851L3.696 10.121L0.172996 6.76501C-0.156004 6.45101 0.0149962 5.87701 0.455996 5.81501L5.354 5.11901L7.538 0.792012C7.735 0.402012 8.268 0.402012 8.465 0.792012L10.649 5.11901L15.547 5.81501C15.988 5.87701 16.159 6.45101 15.829 6.76501L12.307 10.121L13.137 14.851C13.215 15.294 12.777 15.641 12.391 15.443L8 13.187L3.611 15.443H3.612Z"
                        fill="#FFBA0A"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_519">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_1_519)">
                      <path
                        d="M3.612 15.443C3.226 15.641 2.788 15.294 2.866 14.851L3.696 10.121L0.172996 6.76501C-0.156004 6.45101 0.0149962 5.87701 0.455996 5.81501L5.354 5.11901L7.538 0.792012C7.735 0.402012 8.268 0.402012 8.465 0.792012L10.649 5.11901L15.547 5.81501C15.988 5.87701 16.159 6.45101 15.829 6.76501L12.307 10.121L13.137 14.851C13.215 15.294 12.777 15.641 12.391 15.443L8 13.187L3.611 15.443H3.612Z"
                        fill="#FFBA0A"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_519">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_1_519)">
                      <path
                        d="M3.612 15.443C3.226 15.641 2.788 15.294 2.866 14.851L3.696 10.121L0.172996 6.76501C-0.156004 6.45101 0.0149962 5.87701 0.455996 5.81501L5.354 5.11901L7.538 0.792012C7.735 0.402012 8.268 0.402012 8.465 0.792012L10.649 5.11901L15.547 5.81501C15.988 5.87701 16.159 6.45101 15.829 6.76501L12.307 10.121L13.137 14.851C13.215 15.294 12.777 15.641 12.391 15.443L8 13.187L3.611 15.443H3.612Z"
                        fill="#FFBA0A"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_519">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <div className="mb-1 d-flex px-3 align-items-center gap-1 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 28 29"
                  fill="none"
                >
                  <path
                    d="M21.4881 12.0134C21.4881 9.96174 20.6731 7.99412 19.2224 6.54339C17.7717 5.09266 15.804 4.27765 13.7524 4.27765C11.7007 4.27765 9.73314 5.09266 8.2824 6.54339C6.83167 7.99412 6.01666 9.96174 6.01666 12.0134C6.01666 15.1868 8.5557 19.3228 13.7524 24.2771C18.9491 19.3228 21.4881 15.1868 21.4881 12.0134ZM13.7524 26.6253C7.44863 20.8957 4.29761 16.0239 4.29761 12.0134C4.29761 9.50582 5.29373 7.10095 7.06685 5.32784C8.83997 3.55472 11.2448 2.55859 13.7524 2.55859C16.26 2.55859 18.6648 3.55472 20.4379 5.32784C22.2111 7.10095 23.2072 9.50582 23.2072 12.0134C23.2072 16.0239 20.0562 20.8957 13.7524 26.6253Z"
                    fill="#7D7D7D"
                  />
                  <path
                    d="M13.7524 14.5928C14.4363 14.5928 15.0922 14.3211 15.5757 13.8375C16.0593 13.3539 16.331 12.6981 16.331 12.0142C16.331 11.3303 16.0593 10.6744 15.5757 10.1909C15.0922 9.70728 14.4363 9.43561 13.7524 9.43561C13.0685 9.43561 12.4127 9.70728 11.9291 10.1909C11.4455 10.6744 11.1738 11.3303 11.1738 12.0142C11.1738 12.6981 11.4455 13.3539 11.9291 13.8375C12.4127 14.3211 13.0685 14.5928 13.7524 14.5928ZM13.7524 16.3118C12.6126 16.3118 11.5195 15.859 10.7135 15.0531C9.90756 14.2471 9.45477 13.154 9.45477 12.0142C9.45477 10.8744 9.90756 9.78126 10.7135 8.9753C11.5195 8.16934 12.6126 7.71655 13.7524 7.71655C14.8922 7.71655 15.9853 8.16934 16.7913 8.9753C17.5972 9.78126 18.05 10.8744 18.05 12.0142C18.05 13.154 17.5972 14.2471 16.7913 15.0531C15.9853 15.859 14.8922 16.3118 13.7524 16.3118Z"
                    fill="#7D7D7D"
                  />
                </svg>
                <p className={`m-0 p-0 ${style.days}`}>{item.country}</p>
              </div>
              <div className="py-0 px-3 mx-0 mb-2 d-flex align-items-center gap-3">
                <p className={`m-0 p-0 ${style.price}`}>{item.price}</p>
                <del className={`m-0 p-0 ${style.originalPrice}`}>
                  {item.orignalPrice}
                </del>
              </div>
              <p className={`py-0 px-3 my-1 mx-0 ${style.desc}`}>{item.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Packages;
