import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import style from "./partners.module.css";
const Partners = ({ data }) => {
  const swiperOptions = {
    loop: true,
    centeredSlides: true,
    spaceBetween: 0,

    autoplay: {
      delay: 1500,
      disableOnInteraction: false,
    },
    pagination: {
      clickable: true,
    },
    breakpoints: {
      500: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      900: {
        slidesPerView: 3,
      },
      1024: {
        slidesPerView: 5,
      },
    },
  };
  return (
    <div className="container my-5 pt-3">
      <Swiper
        className=" text-md-start"
        modules={[Autoplay]}
        {...swiperOptions}
      >
        {data.map((item, index) => (
          <SwiperSlide className="text-center" key={index}>
            <img
              alt="partner/img"
              loading="lazy"
              src={item}
              className={`${style.img}`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Partners;
